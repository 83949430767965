import { useContext, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Column } from "react-table";
import Auth from "../../Auth";
import BaseTable from "../../components/BaseTable";

import { parseTime, useCollection } from "../../useFirebase";
import { BackupDrive } from "./types";

const COLUMNS: Column<BackupDrive>[] = [
  {
    Header: "Disk",
    accessor: "disk",
  },
  {
    Header: "Hostname",
    accessor: "hostname",
  },
  {
    Header: "Mountpoint",
    accessor: "mount",
  },
  {
    Header: "Updated",
    accessor: ({ timestamp }) => parseTime(timestamp).toRelative(),
  },
];

interface SearchResultsProps {
  search: string;
}
function SearchResults({ search }: SearchResultsProps) {
  const { user } = useContext(Auth);
  const filter = useMemo(() => ["logs", "array-contains", search], [search]);
  const results = useCollection<BackupDrive>("backup-disk", {
    filter,
    operators: user?.admin ? undefined : user?.operators ?? [],
  });

  if (search.length === 0) {
    return null;
  }

  return (
    <div className="pt-3">
      <h5>Backup locations for "{search}"</h5>
      {results.length === 0 && <h5>No results</h5>}
      {results.length > 0 && (
        <div className="pt-4" style={{ maxWidth: 900 }}>
          <BaseTable columns={COLUMNS} data={results} />
        </div>
      )}
    </div>
  );
}

export default function LogSearch() {
  // Track the search input text separately from the actual search string.
  // On submit, we copy `searchInput` into `search`.
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (e.target.value === "") {
      setSearch("");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setSearchInput(searchInput.trim());
    setSearch(searchInput.trim());
    e.preventDefault();
  };

  return (
    <>
      <h4 className="mt-3">Search for a log</h4>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} sm={5} className="me-sm-2 mb-2">
            <Form.Control
              placeholder="log name"
              value={searchInput}
              type="search"
              onChange={handleChange}
            />
            <Form.Text>NOTE: Only finds logs with exact name match</Form.Text>
          </Form.Group>
          <Col sm={2}>
            <Button className="mb-sm-2" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <SearchResults search={search} />
    </>
  );
}

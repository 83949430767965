import firebase from "../../firebase";

import { parseTime } from "../../useFirebase";
import { BackupDrive } from "./types";

function drivesToCSV(drives: BackupDrive[]): string {
  const header = ["log", "hostname", "disk", "mount", "updated"].join(",");
  const rows = [header].concat(
    drives.flatMap((d) =>
      d.logs.map((l) =>
        [l, d.hostname, d.disk, d.mount, parseTime(d.timestamp).toISO()].join(
          ","
        )
      )
    )
  );
  return rows.join("\n");
}

export async function downloadLogBackupsCSV(completion_cb: () => void) {
  // Load all drives from firebase and download as CSV by opening
  // new window. Calls `completion_cb` when done.
  //
  // We don't use `useCollection` because we only need a single response,
  // not a subscription.
  const query = firebase.firestore().collection("backup-disk");
  return query
    .get()
    .then((snapshot: firebase.firestore.QuerySnapshot) => {
      let drives = snapshot.docs.map(
        (doc: any) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as BackupDrive)
      );
      console.log(`downloading data for ${drives.length} drives`);
      const blob = new Blob([drivesToCSV(drives)], { type: "text/csv" });
      const fileURL = URL.createObjectURL(blob);
      // Create a link element and trigger the download.
      const link = document.createElement("a");
      link.download = "logs.csv";
      link.href = fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      completion_cb();
    })
    .catch((error: firebase.firestore.FirestoreError) => {
      console.log(error);
      completion_cb();
    });
}

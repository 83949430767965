import { useContext, useMemo, useState } from "react";
import { Column } from "react-table";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import Auth, { User } from "../../Auth";
import BaseTable from "../../components/BaseTable";
import { parseTime, useCollection } from "../../useFirebase";
import { BackupDrive } from "./types";

function logTime(logName: string): DateTime {
  const dateStr = logName.split("-")[0];
  return DateTime.fromISO(dateStr, { zone: "utc" });
}

const COLUMNS: Column<BackupDrive>[] = [
  {
    Header: "Date of newest log",
    id: "newestLog",
    accessor: "newestLog",
    Cell: ({ value }: { value: string }) => {
      const dateTime = logTime(value);
      if (!dateTime.isValid) {
        return value;
      }
      return dateTime.toISODate();
    },
  },
  {
    Header: "Disk",
    accessor: "disk",
  },
  {
    Header: "Processor",
    accessor: "hostname",
  },
  {
    Header: "Last inserted",
    accessor: ({ timestamp }) => parseTime(timestamp).toRelative(),
  },
];

export default function DriveList() {
  const { user } = useContext(Auth);
  const [filterOperator, setFilterOperator] = useState("");
  // Admin user must filter for specific operator before getting any results.
  const mustFilterOperator = user?.admin;

  if (!user) {
    // Shouldn't get here because of protected routes.
    return <div>Login required...</div>;
  }

  return (
    <>
      <h4 className="mt-3">Oldest backup drives</h4>
      {mustFilterOperator && (
        <OperatorFilter
          operator={filterOperator}
          setOperator={setFilterOperator}
        />
      )}
      {(!mustFilterOperator || filterOperator !== "") && (
        <DriveListImpl user={user} filterOperator={filterOperator} />
      )}
    </>
  );
}

interface DriveListImplProps {
  user: User;
  filterOperator: string;
}

function DriveListImpl({ user, filterOperator }: DriveListImplProps) {
  // We want to see the oldest backups first.
  const orderBy = useMemo(() => ["newestLog", "asc"], []);

  // Filter by operator (if admin, the requested operaotr, otherwise the
  // operators assigned to the user).
  const operators = useMemo(() => {
    if (user.admin) {
      return [filterOperator];
    } else {
      return user.operators ?? [];
    }
  }, [user, filterOperator]);

  // Don't show archived disks.
  const filter = useMemo(() => ["archiveReason", "==", ""], []);

  const data = useCollection<BackupDrive>("backup-disk", {
    orderBy,
    operators,
    filter,
  });

  return (
    <>
      <div style={{ maxWidth: 900 }} className="mb-4">
        <BaseTable columns={COLUMNS} data={data} enableSorting={false} />
      </div>
    </>
  );
}

interface OperatorFilterProps {
  operator: string;
  setOperator: (operator: string) => void;
}

function OperatorFilter({ operator, setOperator }: OperatorFilterProps) {
  const [input, setInput] = useState(operator);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    if (e.target.value === "") {
      setOperator("");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setInput(input.trim());
    setOperator(input.trim());
    e.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col} sm={5} className="me-sm-2 mb-2">
          <Form.Control
            placeholder="operator name"
            value={input}
            type="search"
            onChange={handleChange}
          />
        </Form.Group>
        <Col sm={2}>
          <Button className="mb-sm-2" type="submit">
            Filter
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

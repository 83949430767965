export function cellStyle(run: number, err: number) {
  if (run > 0 && err > 0) {
    return {
      backgroundImage:
        "linear-gradient(45deg, greenyellow 50%, lightcoral 51%)",
    };
  } else if (run > 0) {
    return { backgroundColor: "greenyellow" };
  } else if (err > 0) {
    return { backgroundColor: "lightcoral" };
  } else {
    return { backgroundColor: "transparent" };
  }
}

export function shortId(id: string) {
  return id.replace("processor-", "p");
}

import { useContext } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

import Auth from "../../Auth";
import DownloadButton from "./DownloadButton";
import DriveList from "./DriveList";
import LogSearch from "./LogSearch";

export default function LogBackups() {
  const match = useRouteMatch();
  const { user } = useContext(Auth);

  return (
    <Switch>
      <Route path={match.path}>
        <div>
          [ <Link to="/">Home</Link> ]
        </div>
        <h2>Log backups</h2>
        {user?.admin && (
          <div>
            <DownloadButton />
          </div>
        )}
        <LogSearch />
        <hr />
        <DriveList />
      </Route>
    </Switch>
  );
}

import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import { downloadLogBackupsCSV } from "./utils";

export default function DownloadButton() {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    setDownloading(true);
    console.log("downloading!");
    await new Promise((resolve) => setTimeout(resolve, 4000));
    setDownloading(false);
  }, []);

  useEffect(() => {
    // When "downloading" toggles to true, we initiate the download.
    if (downloading) {
      downloadLogBackupsCSV(() => setDownloading(false));
    }
  }, [downloading]);

  return (
    <Button
      className="mt-2 mb-4"
      variant="outline-primary"
      onClick={handleDownload}
      disabled={downloading}
    >
      {downloading ? "Downloading..." : "Download as CSV"}
    </Button>
  );
}
